import React from "react"
import { Link } from "gatsby"
import Typed from "typed.js"
import AnchorLink from "react-anchor-link-smooth-scroll"
import ShopifyIcon from "../elements/shopify-icon"

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      menuOpen: false,
    }

    this.toggleMenu = this.toggleMenu.bind(this)
  }

  componentDidMount() {
    if (this.props.page === "homepage") {
      const strings = this.props.strings.allContentfulHeaderTextStrings.edges.map(
        edge => {
          return edge.node.string
        }
      )

      const options = {
        strings,
        typeSpeed: 90,
        startDelay: 1300,
        backSpeed: 60,
        backDelay: 1800,
        loop: true,
        loopCount: false,
        showCursor: false,
        cursorChar: "",
      }

      const typed = new Typed(".header-main__typing", options)

      typed.start()
    }
  }

  toggleMenu = () => {
    this.setState({
      menuOpen: !this.state.menuOpen,
    })
  }

  render() {
    let menuStatus

    if (this.state.menuOpen) {
      menuStatus = " open"
    } else {
      menuStatus = ""
    }

    let headerMain = null

    switch (this.props.page) {
      case "splash-page":
        headerMain = (
          <div className="header__title header__title--splash">
            <div className="header__main header-main header-main--splash">
              <div className="header-main__content header-main__content--splash">
                <p className="header-main__hi">Hi there!</p>
                <h1>
                  I'm <span className="accent">Amanda</span>
                  <span className="comma">,</span>
                </h1>

                <p className="header-main__splash-text">
                  {this.props.splashText.splashText.splashText}
                </p>

                <p className="header-main__splash-text">
                  <span>{this.props.splashText.splashText2}</span>
                  <a
                    className="header-main__splash-email"
                    href="mailto:amanda@amandasilberberg.com"
                  >
                    {this.props.splashText.splashContact}
                  </a>
                </p>
              </div>

              <div className="header-main__skills">
                <ul className="skills__list">
                  {this.props.skills.map((edge, i) => {
                    if (edge.node.deviconIconName === "Shopify") {
                      return (
                        <li className="skills__item" key={i}>
                          <ShopifyIcon color="white" size={62} />
                        </li>
                      )
                    } else {
                      return (
                        <li className="skills__item" key={i}>
                          <i
                            className={`devicon-${edge.node.deviconClassName}`}
                          />
                        </li>
                      )
                    }
                  })}
                </ul>
              </div>
            </div>
          </div>
        )
        break

      case "homepage":
        headerMain = (
          <div className="header__main header-main">
            <div className="header-main__content">
              <p className="header-main__hi">Hi there!</p>
              <h1>
                I'm <span className="accent">Amanda</span>
                <span className="comma">,</span>
              </h1>
              <div className="header-main__subtitle">
                <p className="header-main__dev">Web developer &</p>
                <p className="header-main__typing" />
              </div>
            </div>

            <AnchorLink
              href="#portfolio"
              className="button button--blue header-main__portfolio-link"
            >
              See what I've been up to
            </AnchorLink>
          </div>
        )
        break

      case "about":
        headerMain = (
          <div className="header__title">
            <h1>
              <span className="accent bg-100">About</span> Me
            </h1>

            <div className="header__breadcrumbs">
              <Link to="/" className="header__breadcrumbs-item">
                Home
              </Link>
              <p className="header__breadcrumbs-item">About</p>
            </div>
          </div>
        )
        break

      case "portfolio":
        headerMain = (
          <div className="header__title">
            <h1>
              My <span className="accent">Portfolio</span>
            </h1>

            <div className="header__breadcrumbs">
              <Link to="/" className="header__breadcrumbs-item">
                Home
              </Link>
              <p className="header__breadcrumbs-item">Portfolio</p>
            </div>
          </div>
        )
        break

      case "side-projects":
        headerMain = (
          <div className="header__title">
            <h1>
              Side <span className="accent">Projects</span>
            </h1>

            <div className="header__breadcrumbs">
              <Link to="/" className="header__breadcrumbs-item">
                Home
              </Link>
              <p className="header__breadcrumbs-item">Side Projects</p>
            </div>
          </div>
        )
        break

      case "casestudy":
        headerMain = (
          <div className="header__title">
            <h1>
              Case <span className="accent bg-100">Study</span>
            </h1>
            <h2 className="header__piece-title">
              {this.props.piece.pieceTitle}
            </h2>

            <div className="header__breadcrumbs">
              <Link to="/" className="header__breadcrumbs-item">
                Home
              </Link>
              <Link
                to="/portfolio"
                className="header__breadcrumbs-item header__breadcrumbs-item--middle"
              >
                Portfolio
              </Link>
              <p className="header__breadcrumbs-item">
                {this.props.piece.pieceTitle}
              </p>
            </div>
          </div>
        )
        break

      default:
        headerMain = null
    }

    return (
      <header
        className={`header${
          this.props.page === "homepage"
            ? " header--100h"
            : this.props.page === "splash-page"
            ? " header-splash"
            : "header--50h"
        }`}
      >
        <div className="wrapper-big">
          {this.props.page === "splash-page" ? (
            <div className="header__nav header__nav--splash">
              <div className="header__logo logo">
                <div className="logo__outer">
                  <div className="logo__inner">
                    <p className="logo__letter logo__letter--a">A</p>
                    <p className="logo__letter logo__letter--s">S</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="header__nav">
              <div className="header__logo logo">
                <Link to="/">
                  <div className="logo__outer">
                    <div className="logo__inner">
                      <p className="logo__letter logo__letter--a">A</p>
                      <p className="logo__letter logo__letter--s">S</p>
                    </div>
                  </div>
                </Link>
              </div>

              <nav className="header__menu header-menu">
                <div
                  className={`header-menu__overlay ${menuStatus}`}
                  onClick={this.toggleMenu}
                />

                {!this.props.showSplashPage && (
                  <ul className={`header-menu__list${menuStatus}`}>
                    <li className="header-menu__item">
                      <Link to="/" className="header-menu__link">
                        Home
                      </Link>
                    </li>

                    <li className="header-menu__item">
                      <Link to="/about" className="header-menu__link">
                        About
                      </Link>
                    </li>

                    <li className="header-menu__item">
                      <Link to="/portfolio" className="header-menu__link">
                        Portfolio
                      </Link>
                    </li>

                    <li className="header-menu__item">
                      {this.props.page === "homepage" ? (
                        <AnchorLink
                          href="#contact"
                          className="header-menu__link"
                        >
                          Contact
                        </AnchorLink>
                      ) : (
                        <Link to="/#contact" className="header-menu__link">
                          Contact
                        </Link>
                      )}
                    </li>
                  </ul>
                )}

                <button
                  className={`header-menu__hamburger hamburger${menuStatus}`}
                  onClick={this.toggleMenu}
                >
                  <span />
                  <span />
                  <span />
                </button>
              </nav>
            </div>
          )}

          {headerMain}
        </div>
      </header>
    )
  }
}

export default Header

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SocialLink = (props) => (
  <li>
    <a href={props.url} target="_blank" rel="noopener noreferrer" className="footer__social-link">
      <FontAwesomeIcon icon={[props.iconCollection, props.iconName]} />
    </a>
  </li>
)

export default SocialLink;
import React from "react"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { far } from "@fortawesome/free-regular-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"
import Helmet from "react-helmet"
import Header from "./sections/header"
import Footer from "./sections/footer"
import favicon from "../../static/favicon.png"

library.add(fas, far, fab)

const Layout = props => (
  <div className="site-wrapper">
    <Helmet>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta http-equiv="X-UA-Compatible" content="ie=edge" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="Amanda Silberberg | Web Developer" />
      <meta name="twitter:creator" content="@AmandaCodes_" />
      <meta property="og:title" content="Amanda Silberberg | Web Developer" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="http://amandasilberberg.com/" />
      <meta
        property="og:image"
        content="http://amandasilberberg.com/public/assets/website-screenshot.png"
      />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:width" content="1280" />
      <meta property="og:image:height" content="800" />
      <meta
        property="og:image:alt"
        content="Screenshot of Amanda Silberberg's Portfolio"
      />
      <meta
        property="og:description"
        content="Hi, I'm Amanda! I'm a Web Developer based out of Toronto."
      />
      <link type="image/png" href={favicon} rel="icon" />
      <title>Amanda Silberberg | Web Developer</title>
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/gh/devicons/devicon@v2.14.0/devicon.min.css"
      />
    </Helmet>
    <Header
      page={props.page}
      strings={props.headerStrings}
      piece={props.piece}
      splashText={props.splashText}
      skills={props.skills}
      showSplashPage={props.showSplashPage}
    />

    {props.children}

    <Footer />
  </div>
)

export default Layout

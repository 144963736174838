import React from 'react';
import SocialLink from '../elements/social-link';
import { StaticQuery, graphql } from 'gatsby';

const currentYear = new Date().getFullYear();

const Footer = () => (
  <StaticQuery
    query={graphql`
      query SocialLinksQuery {
        allContentfulSocialLink {
          edges {
            node {
              url
              iconName
              iconCollection
            }
          }
        }
      }
    `}

    render={data => (
      <footer className="footer">
        <ul className="footer__social">{
          data.allContentfulSocialLink.edges.map((edge, i) => {
            return (
              <SocialLink key={i} url={edge.node.url} iconName={edge.node.iconName} iconCollection={edge.node.iconCollection} />
            )
          })
        }</ul>
        <a href="mailto:amanda@amandasilberberg.com" className="footer__email">amanda@amandasilberberg.com</a>
        <p className="footer__copyright"><span>©</span> Amanda Silberberg {currentYear}</p>
      </footer>
    )}
  />
)

export default Footer;